<template>
  <div class="w-full flex flex-col lg:flex-row h-full">
    <apexchart class="flex-1 max-height" height="100%" type="line" :options="options" :series="value"></apexchart>
    <slot name="actions"></slot>
  </div>
</template>

<script>
export default {
  name: 'AsterixLineChart',
  props: {
    options: {
      type: Object,
      default: () => {},
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
