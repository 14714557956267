<template>
  <div v-if="loading" class="bg-white p-6 pb-4 rounded-lg w-full h-full">
    <header class="block sm:flex items-start">
      <div v-if="!hideTotals" class="flex-1 text-left">
        <h1 class="text-gray-500 uppercase font-bold text-xs">{{ title }}</h1>
        <slot :total="total">
          <p class="text-gray-800 font-bold text-3xl">
            <slot name="total" :total="total">{{ total | thousandSeparator }}</slot>
          </p>
        </slot>
      </div>
      <div v-else class="flex-1 text-left"></div>
      <button-group v-model="optionSelected" :options="chartOptions" class="justify-center" @change="onOptionChange" />
    </header>
    <asterix-line-chart
      v-if="!isEmpty && !loadingChartFormat"
      :key="keyChart"
      :value="chartSeriesFullDateRange"
      :options="_options"
    />
    <div v-else class="mt-4">
      <asterix-no-data
        :hide-image="hideNoDataImage"
        title="We don't have enough data to show an accurate chart"
        subtitle="Change the search criteria or try again later"
      />
    </div>
  </div>
  <analytics-chart-loading v-else />
</template>

<script>
import AsterixLineChart from '@/components/organisms/shared/asterixCharts/AsterixLineChart.vue';
import AnalyticsChartLoading from '@/components/organisms/shared/asterixCharts/AnalitycsChartLoading.vue';
import ButtonGroup from '@/components/molecules/ButtonGroup';
import { thousandSeparator } from '@/filters/thousandSeparator';
import { CONFIG, CHART_TYPES } from '@/components/organisms/shared/asterixCharts/config';
import { getDatesByInterval } from '@/utils/dateTime/getDatesByInterval';

export default {
  name: 'AnalyticsChart',
  components: {
    AsterixLineChart,
    ButtonGroup,
    AnalyticsChartLoading,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  filters: {
    thousandSeparator,
  },
  props: {
    chartOptions: { type: Array, default: () => [] },
    defaultOptionSelected: { type: String, default: () => null },
    chartSeries: { type: Array, default: () => [] },
    dateEnd: { type: Date, default: null },
    dateStart: { type: Date, default: new Date() },
    hideNoDataImage: { type: Boolean, default: () => false },
    loading: { type: Boolean, default: () => false },
    yaxis: { type: [Object, Array], default: () => null },
    randomColors: { type: Boolean, default: () => false },
    tooltip: { type: Object, default: () => ({}) },
    dataType: { type: String, default: () => CHART_TYPES.DAY },
    hideTotals: { type: Boolean, default: () => false },
  },
  data: () => ({
    total: 0,
    chartSeriesFullDateRange: [],
    optionSelected: 0,
    options: CONFIG.areaChart,
    keyChart: 0,
    loadingChartFormat: true,
  }),
  computed: {
    isEmpty() {
      return !this.chartSeriesFullDateRange.length;
    },
    title() {
      return this.chartOptions[this.optionSelected]?.name;
    },
    _options() {
      return {
        ...this.options,
        yaxis: {
          ...CONFIG.areaChart.yaxis,
          ...this.yaxis,
          ...this.options.yaxis,
        },
        colors: this.randomColors ? undefined : CONFIG.areaChart.colors,
        tooltip: {
          ...CONFIG.areaChart.tooltip,
          ...this.tooltip,
        },
      };
    },
  },
  watch: {
    chartSeries: 'formatChartSeriesFullDateRange',
    defaultOptionSelected: 'checkOptionSelected',
  },
  mounted() {
    this.formatChartSeriesFullDateRange();
  },
  methods: {
    onOptionChange(option) {
      this.$emit('change', option);
    },
    updateYaxisOptions(yaxisOptions, index) {
      const newOptions = { ...this._options };
      if (newOptions.yaxis instanceof Array) {
        newOptions.yaxis = {
          ...newOptions.yaxis[index],
          ...yaxisOptions,
        };
      } else {
        newOptions.yaxis = {
          ...newOptions.yaxis,
          ...yaxisOptions,
        };
      }

      this.options = newOptions;
    },
    updateXaxisOptions({ labels, ...options }) {
      const newOptions = {
        ...this.options,
      };
      newOptions.xaxis = {
        ...newOptions.xaxis,
        labels,
        ...options,
      };

      this.options = newOptions;
    },
    updateFillOptions(fillType) {
      const newOptions = {
        ...this.options,
      };
      newOptions.fill = {
        type: fillType,
      };
      this.options = newOptions;
    },
    resetChartOptions() {
      this.options = CONFIG.areaChart;
    },
    formatChartSeriesFullDateRange() {
      this.resetChartOptions();
      this.loadingChartFormat = true;
      const newSeries = [];
      this.options.stroke.width = 4;
      this.total = 0;
      this.updateFillOptions('solid');
      this.chartSeries.forEach(({ name, type, data, totalSkip, yaxisConfigExtend, xaxisConfigExtend }, index) => {
        if (yaxisConfigExtend) {
          this.updateYaxisOptions(yaxisConfigExtend, index);
        }
        if (xaxisConfigExtend) {
          this.updateXaxisOptions(xaxisConfigExtend, index);
        }

        const newDataSeries = [];
        if (this.dataType === CHART_TYPES.DAY) {
          const intervalDates = getDatesByInterval(this.dateStart, this.dateEnd);
          intervalDates.forEach(date => {
            const formattedDate = date.toLocaleDateString('en-UK').split('/').reverse().join('-');
            const y = data.has(formattedDate) ? +data.get(formattedDate) : 0;
            if (!totalSkip) {
              this.total += y;
            }

            newDataSeries.push({ x: formattedDate, y });
          });
        } else {
          data.forEach((value, key) => {
            newDataSeries.push({ x: key, y: value });
            this.total += value;
          });
        }
        newSeries.push({ name, type, data: newDataSeries });
      });
      this.keyChart++;
      this.chartSeriesFullDateRange = newSeries;
      this.loadingChartFormat = false;
    },
    checkOptionSelected(option) {
      const checkOption = this.chartOptions.findIndex(op => op.id === option);
      if (checkOption !== this.optionSelected) this.optionSelected = checkOption;
    },
  },
};
</script>

<style scoped>
::v-deep .max-height {
  min-height: 200px;
}
</style>
