export const CONFIG = {
  areaChart: {
    chart: {
      toolbar: {
        show: false,
      },
      locales: [
        {
          name: 'en',
          options: {
            months: [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'en',
      zoom: {
        enabled: false,
      },
    },
    colors: [
      '#ED8936',
      '#2D66F9',
      '#008FFB',
      '#00E396',
      '#FEB019',
      '#FF4560',
      '#775DD0',
      '#F86624',
      '#F9C80E',
      '#43BCCD',
      '#662E9B',
      '#ea3546',
      '#53868B',
      '#7FFFD4',
      '#FFD700',
    ],
    stroke: {
      curve: 'smooth',
      width: 4,
    },
    fill: {
      type: ['gradient', 'solid'],
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: 'dark',
    },
    markers: {
      size: 5,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      axisBorder: {
        show: false,
        color: '#78909C',
        height: 0,
        width: '100%',
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: 0,
      },
    },
  },
};

export const CHART_TYPES = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  HOUR: 'HOUR',
};
