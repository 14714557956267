var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "flex flex-col bg-white p-6 rounded-lg w-full" },
      [
        _c("div", { staticClass: "flex w-full justify-between" }, [
          _c("div", { staticClass: "flex-col" }, [
            _c("div", {
              staticClass: "w-12 h-3 bg-gray-400 animate-pulse rounded",
            }),
            _c("div", {
              staticClass: "w-32 h-8 bg-gray-400 animate-pulse rounded mt-2",
            }),
          ]),
          _c("div", {
            staticClass: "w-64 h-8 bg-gray-400 animate-pulse rounded",
          }),
        ]),
        _c("div", {
          staticClass: "w-full h-48 bg-gray-400 animate-pulse mt-2 rounded",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }