/**
 * Returns an array of dates between the start and end date (both included).
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Date[]} dates
 */

export function getDatesByInterval(startDate = new Date(), endDate = new Date()) {
  const dates = [];
  let currentDate = startDate;
  const addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = addDays.call(currentDate, 1);
  }
  return dates;
}
