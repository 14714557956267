<template>
  <div class="flex flex-col bg-white p-6 rounded-lg w-full">
    <div class="flex w-full justify-between">
      <div class="flex-col">
        <div class="w-12 h-3 bg-gray-400 animate-pulse rounded"></div>
        <div class="w-32 h-8 bg-gray-400 animate-pulse rounded mt-2"></div>
      </div>
      <div class="w-64 h-8 bg-gray-400 animate-pulse rounded"></div>
    </div>
    <div class="w-full h-48 bg-gray-400 animate-pulse mt-2 rounded"></div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsChartLoading',
};
</script>
