var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "bg-white p-6 pb-4 rounded-lg w-full h-full" },
        [
          _c(
            "header",
            { staticClass: "block sm:flex items-start" },
            [
              !_vm.hideTotals
                ? _c(
                    "div",
                    { staticClass: "flex-1 text-left" },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-gray-500 uppercase font-bold text-xs",
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _vm._t(
                        "default",
                        function () {
                          return [
                            _c(
                              "p",
                              {
                                staticClass: "text-gray-800 font-bold text-3xl",
                              },
                              [
                                _vm._t(
                                  "total",
                                  function () {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("thousandSeparator")(_vm.total)
                                        )
                                      ),
                                    ]
                                  },
                                  { total: _vm.total }
                                ),
                              ],
                              2
                            ),
                          ]
                        },
                        { total: _vm.total }
                      ),
                    ],
                    2
                  )
                : _c("div", { staticClass: "flex-1 text-left" }),
              _c("button-group", {
                staticClass: "justify-center",
                attrs: { options: _vm.chartOptions },
                on: { change: _vm.onOptionChange },
                model: {
                  value: _vm.optionSelected,
                  callback: function ($$v) {
                    _vm.optionSelected = $$v
                  },
                  expression: "optionSelected",
                },
              }),
            ],
            1
          ),
          !_vm.isEmpty && !_vm.loadingChartFormat
            ? _c("asterix-line-chart", {
                key: _vm.keyChart,
                attrs: {
                  value: _vm.chartSeriesFullDateRange,
                  options: _vm._options,
                },
              })
            : _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c("asterix-no-data", {
                    attrs: {
                      "hide-image": _vm.hideNoDataImage,
                      title:
                        "We don't have enough data to show an accurate chart",
                      subtitle: "Change the search criteria or try again later",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      )
    : _c("analytics-chart-loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }